import { Slot } from '@radix-ui/react-slot';
import { mergeClasses } from '@/src/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center ring-offset-background transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        default: 'bg-primary text-black hover:bg-primary/90',

        black: 'bg-dark text-white text-xl font-semibold hover:bg-black',
        dark: 'bg-neutral-900 flex justify-center items-center text-white font-bold rounded-[100px] text-base hover:bg-black tracking-wide',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input rounded-lg bg-background hover:bg-zinc-100 hover:text-accent-foreground',
        'outline-black':
          'border border-input border-dark bg-background hover:bg-zinc-100 hover:text-accent-foreground',
        outlineGray:
          'border shadow-sm border-gray-600 bg-white bg-white flex justify-center items-center text-gray-600 font-bold rounded-full text-base hover:bg-gray-600 hover:text-white tracking-wide',
        ghost: 'bg-transparent rounded-full hover:bg-neutral-200',
        link: 'text-primary underline-offset-4 hover:underline',
        gray: 'bg-gray-600 hover:bg-gray-700 rounded-lg text-white text-sm font-semibold',

        // heart and soul buttons below here
        primary:
          'bg-neutral-900 hover:bg-neutral-950 text-white rounded-full active:bg-neutral-950 disabled:bg-neutral-200',
        secondary:
          'bg-transparent place-items-center box-border shadow border border-neutral-400 hover:bg-neutral-50 active:bg-neutral-100 transition-colors duration-300',
        social: 'p-4 rounded-lg border border-rebuild-gray-500 shadow-sm',
        brand:
          'text-white text-lg font-semibold leading-7 tracking-wide bg-primary flex justify-center items-center rounded-full hover:bg-[#3a8251] tracking-wide disabled:bg-neutral-300 disabled:cursor-not-allowed disabled:pointer-events-none',
        error:
          'text-white py-[10px] px-5 rounded-full bg-red-500 hover:bg-red-600 active:bg-red-700 transition-all duration-300 font-bold',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-4 py-2',
        lg: 'h-14 rounded-md px-6 py-4 leading-4',
        icon: 'h-10 w-10',
        // Heart & soul system
        base: 'h-10 px-5 py-2.5 rounded-full text-base',
        xl: 'px-8 py-4 rounded-full text-lg',
        xs: 'h-7 px-4 py-1 rounded-full text-sm',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={mergeClasses(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
