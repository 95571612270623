'use client';
import { useEffect, useRef } from 'react';
import { DrawerProps } from './interface';
import { mergeClasses } from '@/src/lib';

const Drawer = ({
  isOpen,
  onClose,
  children,
  height = '50vh',
  closeButtonClasses,
  contentClassName,
  modalStyle,
}: DrawerProps) => {
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isOpen &&
        drawerRef.current &&
        !drawerRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 shadow-lg transition-colors duration-500 flex items-end h-full lg:w-auto lg:items-center justify-center  ${
        isOpen
          ? 'z-50 visible bg-rebuild-dark/20'
          : '-z-10 invisible bg-rebuild-dark/0'
      }`}
    >
      <div
        ref={drawerRef}
        style={{ minHeight: height }}
        className={mergeClasses(
          `bg-white w-full rounded-tl-2xl rounded-tr-2xl lg:rounded-2xl overflow-hidden relative lg:max-w-[428px] lg:shadow-sm transform ${
            isOpen ? 'translate-y-0 ' : 'translate-y-full'
          } transition-transform duration-500 ease-out`,
          `${contentClassName}`,
        )}
      >
        <button
          className={mergeClasses(
            'absolute top-8 right-8 bg-white rounded-full shadow-md p-1',
            `${closeButtonClasses}`,
            `${modalStyle}`,
          )}
          onClick={onClose}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
              fill="#0F172A"
            />
          </svg>
        </button>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Drawer;
